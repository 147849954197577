import React from "react";
import { motion } from "framer-motion";

import house from "../assets/media/imgs/stills/house.webp";
import choppingTrees from "../assets/media/imgs/stills/chopping_trees.webp";
import cubes from "../assets/media/imgs/stills/cubes.webp";
import flowerGlock from "../assets/media/imgs/stills/flower_glock.webp";
import computerArt from "../assets/media/imgs/stills/computer_art.webp";

import fourInARow from "../assets/media/imgs/stills/four_in_a_row.webp";
import kettleBoule from "../assets/media/imgs/stills/kettle_boule.webp";
import klimat from "../assets/media/imgs/stills/klimat.webp";
import life from "../assets/media/imgs/stills/life.webp";
import lighthouse from "../assets/media/imgs/stills/lighthouse.webp";
import meditation from "../assets/media/imgs/stills/meditation.webp";
import moped from "../assets/media/imgs/stills/moped.webp";

import nintendoFloat from "../assets/media/imgs/stills/nintendo_float.webp";
import office from "../assets/media/imgs/stills/office.webp";
import piano from "../assets/media/imgs/stills/piano.webp";


import piniano from "../assets/media/imgs/stills/piniano.webp";
import polaroid from "../assets/media/imgs/stills/polaroid.webp";
import retroComputer from "../assets/media/imgs/stills/retro_computer.webp";

import stair from "../assets/media/imgs/stills/stair.webp";
import trucker from "../assets/media/imgs/stills/trucker.webp";
import ui_comps from "../assets/media/imgs/stills/ui_comps.webp";
import vattenKanon from "../assets/media/imgs/stills/vatten_kanon.webp";
import legsUp from  "../assets/media/imgs/stills/legs_up.webp";



const Static = () => {
  const images = [
    {
      src: trucker,
      alt: 'a permobile looking like a truck'
    },
    {
      src: house,
      alt: '3d illustration of a house'
    },
    {
      src: choppingTrees,
      alt: 'illustration of chopsticks looking like trees'
    },
    {
      src: cubes,
      alt: '3d cubes looking like game consoles'
    },
    {
      src: piniano,
      alt: 'mix of a piano and pinball'
    }, 
    {
      src: legsUp,
      alt: '3d illustration of a house'
    }, 
    {
      src: polaroid,
      alt: '3d rendered polaroid camera'
    },
    {
      src: flowerGlock,
      alt: 'a gun looking like a flower pot'
    },
    {
      src: kettleBoule,
      alt: 'boule game but instead of regular balls its kettle bells'
    },
    {
      src: piano,
      alt: 'surrealistic piano illustration'
    },

    {
      src: fourInARow,
      alt: 'illustration of a table'
    },
    {
      src: klimat,
      alt: 'bar chart illustration'
    },
    {
      src: meditation,
      alt: 'tv illustration'
    },
    {
      src: moped,
      alt: '3d rendered moped'
    },
    {
      src: office,
      alt: 'illustration of working late'
    },  
    {
      src: stair,
      alt: 'illustration of a stair of coffins'
    },  
    {
      src: ui_comps,
      alt: '3d rendered ui components'
    },  
    {
      src: vattenKanon,
      alt: 'water cannon illustration'
    },
    {
      src: computerArt,
      alt: '3d illustration'
    },
    {
      src: life,
      alt: '3d illustration'
    },
    {
      src: lighthouse,
      alt: '3d illustration of a lighthouse in game style'
    },
    {
      src: nintendoFloat,
      alt: '3d illustration of a surreal house'
    },
    {
      src: retroComputer,
      alt: '3d illustration of a retro computer'
    },
  ];

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  return (
    <div className="min-h-screen flex flex-col px-2 mt-8">
      <motion.main
        className="flex flex-wrap"
        variants={container}
        initial="hidden"
        animate="show"
      >
        {images.map((image, index) => (
          <motion.div
            key={index}
            className="w-full md:w-1/3 p-3 box-border"
            variants={item}
          >
            <div className="relative w-full h-full bg-gray-100">
              <img
                width="600"
                height="600"
                className="w-full h-full object-cover"
                src={image.src}
                alt={image.alt}
              />
              
            </div>
          </motion.div>
        ))}
      </motion.main>
    </div>
  );
};

export default Static;
