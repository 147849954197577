import React from "react";
import { motion } from "framer-motion";

import CBVideo1 from "../assets/media/videos/CB_800x600.mp4";
import CBCover1 from "../assets/media/videos/covers/General_cover_800x600.jpg";
import DesignVideo from "../assets/media/videos/design_800x600.mp4";
import DesignCover from "../assets/media/videos/covers/General_cover_800x600.jpg";
import ASVideo from "../assets/media/videos/AS_800x600.mp4";
import ASCover from "../assets/media/videos/covers/General_cover_800x600.jpg";
import AVideo from "../assets/media/videos/A_600x600.mp4";
import ACover from "../assets/media/videos/covers/General_cover_800x600.jpg";
import SundVideo from "../assets/media/videos/Sund_600x600.mp4";
import SundCover from "../assets/media/videos/covers/General_cover_600x600.jpg";
import MagneticVideo from "../assets/media/videos/Magnetic_600x600.mp4";
import MagneticCover from "../assets/media/videos/covers/General_cover_600x600.jpg";
import PostVideo from "../assets/media/videos/Post_600x600.mp4";
import PostCover from "../assets/media/videos/covers/General_cover_600x600.jpg";
import RoundaboutVideo from "../assets/media/videos/Roundabout_600x600.mp4";
import RoundaboutCover from "../assets/media/videos/covers/General_cover_600x600.jpg";
import FPVideo from "../assets/media/videos/FP_600x600.mp4";
import FPCover from "../assets/media/videos/covers/General_cover_600x600.jpg";
import MVideo from "../assets/media/videos/M_600x600.mp4";
import MCover from "../assets/media/videos/covers/General_cover_600x600.jpg";
import CCVideo from "../assets/media/videos/CC_600x600.mp4";
import CCCover from "../assets/media/videos/covers/General_cover_600x600.jpg";
import SkateVideo from "../assets/media/videos/Skate_600x600.mp4";
import SkateCover from "../assets/media/videos/covers/General_cover_600x600.jpg";
import PatreonVideo from "../assets/media/videos/Patreon_600x600.mp4";
import PatreonCover from "../assets/media/videos/covers/PT_cover_600x600.jpg";


import Letters1Video from "../assets/media/videos/A-I600x600.mp4";
import Letters1Cover from "../assets/media/videos/covers/A-I_cover_600x600.jpg";
import Letters2Video from "../assets/media/videos/J-R600x600.mp4";
import Letters2Cover from "../assets/media/videos/covers/J-R_cover_600x600.jpg";
import Letters3Video from "../assets/media/videos/S-Q600x600.mp4";
import Letters3Cover from "../assets/media/videos/covers/S-Q_cover_600x600.jpg";


import AGVideo from "../assets/media/videos/AG_600x600.mp4";
import AGCover from "../assets/media/videos/covers/General_cover_600x600.jpg";
import DSVideo from "../assets/media/videos/DS_600x600.mp4";
import DSCover from "../assets/media/videos/covers/General_cover_600x600.jpg";
import CubesVideo from "../assets/media/videos/Cubes_600x600.mp4";
import CubesCover from "../assets/media/videos/covers/General_cover_600x600.jpg";

const Animation = () => {
  const videos = [

    {
      src: CBVideo1,
      poster: CBCover1,
    },
    {
      src: ASVideo,
      poster: ASCover,
    },
    {
      src: DesignVideo,
      poster: DesignCover,
    },
    {
      src: Letters1Video,
      poster: Letters1Cover,
    },
    {
      src: Letters2Video,
      poster: Letters2Cover,
    },
    {
      src: Letters3Video,
      poster: Letters3Cover,
    },
    {
      src: AGVideo,
      poster: AGCover,
    },
    {
      src: DSVideo,
      poster: DSCover,
    },
    {
      src: CubesVideo,
      poster: CubesCover,
    },
    {
      src: SundVideo,
      poster: SundCover,
    },
    {
      src: MagneticVideo,
      poster: MagneticCover,
    },
    {
      src: AVideo,
      poster: ACover,
    },
    {
      src: PostVideo,
      poster: PostCover,
    },
    {
      src: SkateVideo,
      poster: SkateCover,
    },
    {
      src: RoundaboutVideo,
      poster: RoundaboutCover,
    },
    {
      src: FPVideo,
      poster: FPCover,
    },
    {
      src: MVideo,
      poster: MCover,
    },
    {
      src: CCVideo,
      poster: CCCover,
    },
    {
      src: PatreonVideo,
      poster: PatreonCover,
    },
  ];

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  return (
    <div className="min-h-screen flex flex-col px-2 mt-8">
      <motion.main
        className="flex flex-wrap"
        variants={container}
        initial="hidden"
        animate="show"
      >
        {videos.map((video, index) => (
          <motion.div
            key={index}
            className="w-full md:w-1/3 p-3 box-border"
            variants={item}
          >
            <div className="relative w-full h-full bg-gray-100">
              <video
                muted
                autoPlay
                loop
                playsInline
                width="600"
                height="600"
                className="w-full h-full object-cover"
                poster={video.poster}
              >
                <source src={video.src} type="video/mp4" />
              </video>
            </div>
          </motion.div>
        ))}
      </motion.main>
    </div>
  );
};

export default Animation;
