import React from "react";

import instagram from "../assets/media/imgs/icons/instagram.png";
import behance from "../assets/media/imgs/icons/behance.png";
import linkedin from "../assets/media/imgs/icons/linkedin.png";
import youtube from "../assets/media/imgs/icons/youtube.png";

const Footer: React.FC = () => {
  const links = [
    {
      href: "https://www.instagram.com/robin.lundin",
      title: "Open Robins Instagram in new page",
      imgSrc: instagram,
      imgAlt: "Instagram logotype",
    },
    {
      href: "https://www.youtube.com/c/robinlundin",
      title: "Open Robins Youtube in new page",
      imgSrc: youtube,
      imgAlt: "Youtube logotype",
    },
    {
      href: "https://www.linkedin.com/in/robin-lundin-420613a9",
      title: "Open Robins Linkedin profile in new page",
      imgSrc: linkedin,
      imgAlt: "Linkedin logotype",
    },
    {
      href: "https://www.behance.net/Robinlundin",
      title: "Open Robins Behance in new page",
      imgSrc: behance,
      imgAlt: "Behance logotype",
    },
  ];

  return (
    <footer className="flex flex-col bg-slate-100 h-96 mt-8">
      <div className="text-center text-lg md:text-lg pt-16 pb-12 px-4">
        Business inquiries and collaboration proposals{" "}
        <a
          href="mailto:robin.lundin@protonmail.com"
          style={{ color: "#0078c8", textDecoration: "underline" }}
        >
          robin.lundin@protonmail.com
        </a>
      </div>

      <div className="flex justify-center items-center ">
        <div className="grid grid-cols-2 sm:flex gap-6  md:gap-10 p-4 b">
          {links.map((link, index) => (
            <a
              key={index}
              href={link.href}
              target="_blank"
              title={link.title}
              rel="noopener noreferrer"
              className="opacity-70 hover:opacity-100 cursor-pointer"
            >
              <img
                className="w-16 md:w-20"
                src={link.imgSrc}
                alt={link.imgAlt}
                width={80}
                height={80}
              />
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
